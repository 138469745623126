import { skipToken } from "@reduxjs/toolkit/dist/query";
import React from "react";
import { useProfileQuery } from "Store/Services/users";
import { getUserInfo } from "Store/Slices/user";
import { App } from "./App";
import LoginScreen from "./Apps/LoginScreen";
import { useAppDispatch, useAppSelector } from "./Hooks/redux";

export const AppLoginRouter: React.FC = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    // @ts-ignore
    dispatch(getUserInfo());
  }, [dispatch]);

  const loggedIn = useAppSelector((state) => state.ui.user.loggedIn);

  // don't get the user profile until we're logged in
  const { data: userProfile } = useProfileQuery(
    loggedIn ? undefined : skipToken
  );

  const authorized = loggedIn && userProfile;

  if (authorized) return <App />;
  else return <LoginScreen />;
};
