import React, { FC } from "react";
import abcImage from "Main/Media/abc.png?as=webp";
import "./login.css";
import Login from "./Login";
import { useAppDispatch, useAppSelector } from "Main/Hooks/redux";
import { useProfileQuery } from "Store/Services/users";
import { getErrorStatusCode } from "Store/Services/getErrorStatusCode";
import { skipToken } from "@reduxjs/toolkit/query";
import { logout } from "Store/Slices/user";

const LoginScreen: FC = (props) => {
  const dispatch = useAppDispatch();
  const loggedIn = useAppSelector((state) => state.ui.user.loggedIn);

  // don't get the user profile until we're logged in
  const {
    isLoading: isProfileLoading,
    isError: isProfileError,
    error: profileError,
  } = useProfileQuery(loggedIn ? undefined : skipToken);

  const failedEntitlementCheck =
    loggedIn && isProfileError && getErrorStatusCode(profileError) === 401;

  // @ts-ignore
  const onLogOut = () => dispatch(logout());

  // handle any other error in fetching profile..

  return (
    <div className="abc-login-wrapper">
      <div className="abc-login-box">
        <div className="abc-login-header">
          <img className="abc-login-header-image" src={abcImage} alt="" />
        </div>
        <div className="abc-login-left">
          {!loggedIn ? (
            <Login />
          ) : (
            <>
              <button onClick={onLogOut}>Log Out</button>
              <span style={{ color: "white" }}>
                {isProfileLoading
                  ? "Profile Loading..."
                  : failedEntitlementCheck
                  ? "UNAUTHORIZED!"
                  : "Unknown Error. Please Try Again"}
              </span>
            </>
          )}
        </div>
        <div className="abc-login-right">
          <button>Login with Google</button>
        </div>
      </div>
    </div>
  );
};

export default LoginScreen;
