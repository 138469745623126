import ararat from "./Media/Locations/ararat/normal.png?as=webp";
import ararat_glow from "./Media/Locations/ararat/hover.png?as=webp";

import hrabarag from "./Media/Locations/hrabarag/normal.png?as=webp";
import hrabarag_glow from "./Media/Locations/hrabarag/hover.png?as=webp";

import lakeSevan from "./Media/Locations/lakeSevan/normal.png?as=webp";
import lakeSevan_glow from "./Media/Locations/lakeSevan/hover.png?as=webp";

import amarasMonastery from "./Media/Locations/amarasMonastery/normal.png?as=webp";
import amarasMonastery_glow from "./Media/Locations/amarasMonastery/hover.png?as=webp";

import mamikBabik from "./Media/Locations/mamikBabik/normal.png?as=webp";
import mamikBabik_glow from "./Media/Locations/mamikBabik/hover.png?as=webp";

import tigerCave from "./Media/Locations/tigerCave/normal.png?as=webp";
import tigerCave_glow from "./Media/Locations/tigerCave/hover.png?as=webp";

import motherArmenia from "./Media/Locations/motherArmenia/normal.png?as=webp";
import motherArmenia_glow from "./Media/Locations/motherArmenia/hover.png?as=webp";

import sardarabad from "./Media/Locations/sardarabad/normal.png?as=webp";
import sardarabad_glow from "./Media/Locations/sardarabad/hover.png?as=webp";

import noravank from "./Media/Locations/noravank/normal.png?as=webp";
import noravank_glow from "./Media/Locations/noravank/hover.png?as=webp";

import khorVirab from "./Media/Locations/khorVirab/normal.png?as=webp";
import khorVirab_glow from "./Media/Locations/khorVirab/hover.png?as=webp";

// import jermuk from "./Media/Locations/jermuk/normal.png?as=webp";
// import jermuk_glow from "./Media/Locations/jermuk/hover.png?as=webp";

import etchmiadzin from "./Media/Locations/etchmiadzin/normal.png?as=webp";
import etchmiadzin_glow from "./Media/Locations/etchmiadzin/hover.png?as=webp";

// import loriFortress from "./Media/Locations/loriFortress/normal.png?as=webp";
// import loriFortress_glow from "./Media/Locations/loriFortress/hover.png?as=webp";

import lastiver from "./Media/Locations/lastiver/normal.png?as=webp";
import lastiver_glow from "./Media/Locations/lastiver/hover.png?as=webp";

import datev from "./Media/Locations/datev/normal.png?as=webp";
import datev_glow from "./Media/Locations/datev/hover.png?as=webp";

import karahounch from "./Media/Locations/karahounch/normal.png?as=webp";
import karahounch_glow from "./Media/Locations/karahounch/hover.png?as=webp";

// import amberd from "./Media/Locations/amberd/normal.png?as=webp";
// import amberd_glow from "./Media/Locations/amberd/hover.png?as=webp";

import garni from "./Media/Locations/garni/normal.png?as=webp";
import garni_glow from "./Media/Locations/garni/hover.png?as=webp";

import dilijan from "./Media/Locations/dilijan/normal.png?as=webp";
import dilijan_glow from "./Media/Locations/dilijan/hover.png?as=webp";

import gyumri from "./Media/Locations/gyumri/normal.png?as=webp";
import gyumri_glow from "./Media/Locations/gyumri/hover.png?as=webp";

import ardashavan from "./Media/Locations/ardashavan/normal.png?as=webp";
import ardashavan_glow from "./Media/Locations/ardashavan/hover.png?as=webp";

export const mapItems = [
  {
    img: ararat,
    img_hover: ararat_glow,
    pos: [285, 502],
    scale: 1.5,
    location: "Ararat",
    key: "ararat",
  },
  {
    img: lakeSevan,
    img_hover: lakeSevan_glow,
    pos: [600, 250],
    location: "LakeSevan",
    key: "lakeSevan",
  },
  {
    img: amarasMonastery,
    img_hover: amarasMonastery_glow,
    pos: [1040, 540],
    scale: 0.9,
    location: "AmarasMonastery",
    key: "amaras",
  },
  {
    img: mamikBabik,
    img_hover: mamikBabik_glow,
    pos: [900, 420],
    location: "MamikBabik",
    key: "mamikBabik",
  },
  {
    img: tigerCave,
    img_hover: tigerCave_glow,
    pos: [950, 280],
    location: "TigerCave",
    key: "tigerCave",
  },
  {
    img: motherArmenia,
    img_hover: motherArmenia_glow,
    pos: [477, 261],
    scale: 0.8,
    location: "MotherArmenia",
    key: "motherArmenia",
  },
  {
    img: hrabarag,
    img_hover: hrabarag_glow,
    pos: [569, 363],
    scale: 0.92,
    location: "Hrabarag",
    key: "hrabarag",
  },
  {
    img: sardarabad,
    img_hover: sardarabad_glow,
    pos: [306, 324],
    scale: 0.94,
    location: "Sardarabad",
    key: "sardarabad",
  },
  {
    img: noravank,
    img_hover: noravank_glow,
    pos: [714, 472],
    scale: 0.817,
    location: "Noravank",
    key: "noravank",
  },
  {
    img: khorVirab,
    img_hover: khorVirab_glow,
    pos: [563, 494],
    scale: 1,
    location: "KhorVirab",
    key: "khorVirab",
  },
  // {
  //   img: jermuk,
  //   img_hover: jermuk_glow,
  //   pos: [1063, 60],
  //   scale: 0.8,
  //   location: "Jermuk",
  //   key: "jermuk",
  // },
  {
    img: etchmiadzin,
    img_hover: etchmiadzin_glow,
    pos: [425, 370],
    scale: 0.776,
    location: "Etchmiadzin",
    key: "etchmiadzin",
  },
  // {
  //   img: loriFortress,
  //   img_hover: loriFortress_glow,
  //   pos: [1132, 65],
  //   scale: 0.776,
  //   location: "LoriFortress",
  //   key: "loriFortress",
  // },
  {
    img: lastiver,
    img_hover: lastiver_glow,
    pos: [578.5, 75.9],
    scale: 0.818,
    location: "Lastiver",
    key: "lastiver",
  },
  {
    img: datev,
    img_hover: datev_glow,
    pos: [880.3, 675.3],
    scale: 0.861,
    location: "Datev",
    key: "datev",
  },
  {
    img: karahounch,
    img_hover: karahounch_glow,
    pos: [886.8, 555.6],
    scale: 0.913,
    location: "Karahounch",
    key: "karahounch",
  },
  // {
  //   img: amberd,
  //   img_hover: amberd_glow,
  //   pos: [1226, 71],
  //   scale: 0.913,
  //   location: "Amberd",
  //   key: "amberd",
  // },
  {
    img: garni,
    img_hover: garni_glow,
    pos: [729, 325],
    scale: 0.826,
    location: "Garni",
    key: "garni",
  },
  {
    img: dilijan,
    img_hover: dilijan_glow,
    pos: [645, 240],
    scale: 0.716,
    location: "Dilijan",
    key: "dilijan",
  },
  {
    img: gyumri,
    img_hover: gyumri_glow,
    pos: [340, 86],
    scale: 0.835,
    location: "Gyumri",
    key: "gyumri",
  },
  {
    img: ardashavan,
    img_hover: ardashavan_glow,
    pos: [409, 184],
    scale: 0.913,
    location: "Ardashavan",
    key: "ardashavan",
  },
];

export const resources = mapItems.flatMap((x) => {
  return [x.img, x.img_hover];
});
