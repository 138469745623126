// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./SosGro_NoLigature.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Armguard.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./DejaVuSans.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./DejaVuSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./DejaVuSansCondensed.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./DejaVuSansCondensed-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "SosGro";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  unicode-range: U+0530-058F;
}

@font-face {
  font-family: "ArmGuard";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
  unicode-range: U+0530-058F;
}

@font-face {
  font-family: "DejaVu";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_2___});
  unicode-range: U+0530-058F;
}

@font-face {
  font-family: "DejaVu";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_3___});
  unicode-range: U+0530-058F;
  font-weight: bold;
}

@font-face {
  font-family: "DejaVu";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_4___});
  unicode-range: U+0530-058F;
  font-stretch: condensed;
}

@font-face {
  font-family: "DejaVu";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_5___});
  unicode-range: U+0530-058F;
  font-stretch: condensed;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/Fonts/fonts.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,4CAAiC;EACjC,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;EACvB,4CAAwB;EACxB,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,4CAA0B;EAC1B,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,4CAA+B;EAC/B,0BAA0B;EAC1B,iBAAiB;AACnB;;AAEA;EACE,qBAAqB;EACrB,4CAAmC;EACnC,0BAA0B;EAC1B,uBAAuB;AACzB;;AAEA;EACE,qBAAqB;EACrB,4CAAwC;EACxC,0BAA0B;EAC1B,uBAAuB;EACvB,iBAAiB;AACnB","sourcesContent":["@font-face {\n  font-family: \"SosGro\";\n  src: url(./SosGro_NoLigature.ttf);\n  unicode-range: U+0530-058F;\n}\n\n@font-face {\n  font-family: \"ArmGuard\";\n  src: url(./Armguard.ttf);\n  unicode-range: U+0530-058F;\n}\n\n@font-face {\n  font-family: \"DejaVu\";\n  src: url(./DejaVuSans.ttf);\n  unicode-range: U+0530-058F;\n}\n\n@font-face {\n  font-family: \"DejaVu\";\n  src: url(./DejaVuSans-Bold.ttf);\n  unicode-range: U+0530-058F;\n  font-weight: bold;\n}\n\n@font-face {\n  font-family: \"DejaVu\";\n  src: url(./DejaVuSansCondensed.ttf);\n  unicode-range: U+0530-058F;\n  font-stretch: condensed;\n}\n\n@font-face {\n  font-family: \"DejaVu\";\n  src: url(./DejaVuSansCondensed-Bold.ttf);\n  unicode-range: U+0530-058F;\n  font-stretch: condensed;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
