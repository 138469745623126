// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
  text-align: center;
  width: 80%;
  margin: auto;
  height: auto;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 25px;
  color: white;
  position: relative;
}

@media (min-aspect-ratio: 2/1) and (max-height: 700px) {
  .App {
    flex-direction: row;
  }
}

.toolbar-grid-area {
  grid-area: toolbar;
}

.app-grid-area {
  grid-area: app;
}

.pixiapp {
  box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/Main/App.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;EAEvB,eAAe;EACf,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE;IACE,mBAAmB;EACrB;AACF;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,+CAA+C;AACjD","sourcesContent":[".App {\n  text-align: center;\n  width: 80%;\n  margin: auto;\n  height: auto;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n  font-size: 25px;\n  color: white;\n  position: relative;\n}\n\n@media (min-aspect-ratio: 2/1) and (max-height: 700px) {\n  .App {\n    flex-direction: row;\n  }\n}\n\n.toolbar-grid-area {\n  grid-area: toolbar;\n}\n\n.app-grid-area {\n  grid-area: app;\n}\n\n.pixiapp {\n  box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.8);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
