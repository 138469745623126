import React from "react";
import AppResizer from "./AppResizer";
import { Toolbar } from "Main/Components/Toolbar/Toolbar";
import HTMLContextRoot from "Main/Components/PixiComponents/HTML/HTMLContextRoot";
import { Stage } from "@inlet/react-pixi";
import PixiApp from "Main/PixiApp";
import { Providers } from "./Providers";
import "./App.css";
import { useDispatch } from "react-redux";
import { setVerticalMenu } from "Store/Slices/app";

export const App: React.FC = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const query = matchMedia("(min-aspect-ratio: 2/1) and (max-height: 700px)");
    if (query.matches) dispatch(setVerticalMenu(true));

    const handler = (event: MediaQueryListEvent) => {
      dispatch(setVerticalMenu(event.matches));
    };

    query.addEventListener("change", handler);

    return () => query.removeEventListener("change", handler);
  });

  return (
    <AppResizer>
      <div className="App">
        <div className="toolbar-grid-area">
          <Toolbar />
        </div>
        <div className="app-grid-area">
          <HTMLContextRoot />
          <Stage
            className="pixiapp"
            width={1300}
            height={750}
            options={{
              backgroundColor: 0xffffff,
              resolution: 1,
            }}
          >
            {/* We need a second level of provider / router.
              The <Stage> component doesn't let these pass to children. */}
            <Providers>
              <PixiApp />
            </Providers>
          </Stage>
        </div>
      </div>
    </AppResizer>
  );
};
