import { useEffect, useState } from "react";

const useWindowFocus = () => {
  const [focused, setFocused] = useState(true);
  const [visible, setVisible] = useState(
    document.visibilityState === "visible"
  );

  const onFocusIn = () => {
    setFocused(true);
  };

  const onFocusOut = () => {
    setFocused(false);
  };

  const onVisibilityChanged = () => {
    setVisible(document.visibilityState === "visible");
  };

  const onPageHide = () => {
    setVisible(false);
  };

  useEffect(() => {
    window.addEventListener("focus", onFocusIn);
    window.addEventListener("blur", onFocusOut);

    document.addEventListener("visibilitychange", onVisibilityChanged);

    // Need 'pagehide' event on Safari, since it doesn't use onvisibilitychange when hidden
    window.addEventListener("pagehide", onPageHide);

    return () => {
      window.removeEventListener("focus", onFocusIn);
      window.removeEventListener("blur", onFocusOut);

      document.removeEventListener("visibilitychange", onVisibilityChanged);
      window.removeEventListener("pagehide", onPageHide);
    };
  });

  return focused && visible;
};

export default useWindowFocus;
