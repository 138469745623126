import { createGenericContext } from "Main/Hooks/createGenericContext";
import { ActivitySessionResult } from "Store/Services/activity";
import { ActivityId } from "./activityId";

type ActivityStatus = "pregame" | "playing" | "completed";

// interface ActivityPlayerData {
//   // can add more stats here such as highscore, etc
//   firstPlay: boolean;
// }

export interface ActivityState {
  activityId: ActivityId;
  status: ActivityStatus;
  paused: boolean;
  //playerData: ActivityPlayerData;
  endData?: Omit<ActivitySessionResult, "ended_at">;
  setPaused: (paused: boolean) => void;
}

const [
  useActivityContext,
  ActivityContextProvider,
] = createGenericContext<ActivityState>();

export { useActivityContext, ActivityContextProvider };
