// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.abc-login-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.abc-login-box {
  width: 600px;
  height: 400px;
  border-radius: 20px;
  border: white solid 2px;
  display: grid;
  grid-template-rows: 150px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "header header" "left right";
}

.abc-login-header {
  grid-area: header;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.abc-login-header-image {
  height: 100%;
}

.abc-login-left {
  grid-area: left;
}

.abc-login-right {
  grid-area: right;
}
`, "",{"version":3,"sources":["webpack://./src/Main/Apps/LoginScreen/login.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;EACb,6BAA6B;EAC7B,8BAA8B;EAC9B,iDAAiD;AACnD;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".abc-login-wrapper {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.abc-login-box {\n  width: 600px;\n  height: 400px;\n  border-radius: 20px;\n  border: white solid 2px;\n  display: grid;\n  grid-template-rows: 150px 1fr;\n  grid-template-columns: 1fr 1fr;\n  grid-template-areas: \"header header\" \"left right\";\n}\n\n.abc-login-header {\n  grid-area: header;\n  display: flex;\n  justify-content: center;\n  padding: 20px;\n}\n\n.abc-login-header-image {\n  height: 100%;\n}\n\n.abc-login-left {\n  grid-area: left;\n}\n\n.abc-login-right {\n  grid-area: right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
