import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "Main/Hooks/useTranslation";

// https://szhsin.github.io/react-menu/
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import "./Toolbar.css";
import abc from "Main/Media/abc.png?as=webp";
import gabig_avatar from "Main/Media/gabigface.png?as=webp";
import { closeMap, setUIModalOpen } from "Store/Slices/app";
import { useAppDispatch, useAppSelector } from "Main/Hooks/redux";

import { useProfileQuery } from "Store/Services/users";

const learn = {
  title: "learn.title",
  items: [
    "learn.items.alphabet",
    "learn.items.vocabulary",
    "learn.items.spelling",
    "learn.items.grammar",
    "learn.items.geography",
    "learn.items.history",
    "learn.items.conversationalPhrases",
  ],
};

const read = {
  title: "read.title",
  items: ["read.items.shortStories", "read.items.novels", "read.items.poems"],
};

const singDance = {
  title: "singDance.title",
  items: ["singDance.items.songs", "singDance.items.dances"],
};

const play = {
  title: "play.title",
  items: ["play.items.educational", "play.items.fun"],
};

export const Toolbar = (props) => {
  const { t } = useTranslation(["toolbar"]);

  const uiModalOpen = useAppSelector((state) => state.ui.app.UIModalOpen);
  const isVertical = useAppSelector((state) => state.ui.app.verticalMenu);
  const dispatch = useAppDispatch();

  const getMenuItems = (items) =>
    items.map((x) => <MenuItem key={x}>{t(x)}</MenuItem>);

  const { data: userProfile } = useProfileQuery();
  const bananas = userProfile?.profile?.bananas || "...";

  const menuProps = {
    direction: isVertical ? "right" : "bottom",
    offsetX: isVertical ? 40 : undefined,
    offsetY: isVertical ? undefined : 20,
    position: "anchor",
  };

  return (
    <div className="toolbar-wrap">
      <div className={`toolbar ${isVertical && "vertical"}`}>
        <Link className="logo-wrap" to="/" onClick={() => dispatch(closeMap())}>
          <img className="logo" src={abc} alt="homepage" />
        </Link>
        <div className={`dropdown-wrap ${isVertical && "vertical"}`}>
          <div className="dropdown">
            <Menu
              {...menuProps}
              menuButton={<MenuButton>{t(learn.title)}</MenuButton>}
            >
              {getMenuItems(learn.items)}
            </Menu>
          </div>
          <div className="dropdown">
            <Menu
              {...menuProps}
              menuButton={<MenuButton>{t(read.title)}</MenuButton>}
            >
              {getMenuItems(read.items)}
            </Menu>
          </div>
          <div className="dropdown">
            <Menu
              {...menuProps}
              menuButton={<MenuButton>{t(singDance.title)}</MenuButton>}
            >
              {getMenuItems(singDance.items)}
            </Menu>
          </div>
          <div className="dropdown">
            <Menu
              {...menuProps}
              menuButton={<MenuButton>{t(play.title)}</MenuButton>}
            >
              {getMenuItems(play.items)}
            </Menu>
          </div>
        </div>
        <div className={`banana-wrap ${isVertical && "vertical"}`}>
          <img
            alt="banana"
            className="banana"
            src="https://img.icons8.com/fluent/48/000000/banana.png"
          />
          <p>{bananas}</p>
        </div>
        <div className={`im-gabig-wrap ${isVertical && "vertical"}`}>
          <img
            alt="my monkey"
            className="im-gabig"
            src={gabig_avatar}
            onClick={() => dispatch(setUIModalOpen(!uiModalOpen))}
          />
        </div>
      </div>
    </div>
  );
};

export default Toolbar;
