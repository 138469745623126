import { createGenericContextUnsafe } from "Main/Hooks/createGenericContext";
import { LocationId } from "./locationId";

export interface LocationState {
  locationId: LocationId;
  parentLocation?: LocationId;
  onGoBack?: () => void;
}

const [
  useLocationContext,
  LocationContextProvider,
] = createGenericContextUnsafe<LocationState>();

export { useLocationContext, LocationContextProvider };
