import { useActivityContext } from "Main/Components/Activity/activityContext";
import { ActivityId } from "Main/Components/Activity/activityId";
import { useProfileQuery, UserProfile } from "Store/Services/users";
import { useAppSelector } from "./redux";

/**
 * Get whether the current activity is paused.
 * Returns true if the activity itself is paused or if the map is open
 * Must be used within an Activity Context.
 */
export const useActivityPaused = () => {
  const mapOpen = useAppSelector((state) => state.ui.app.mapOpen);
  const activityContext = useActivityContext();
  return activityContext.paused || mapOpen;
};

/**
 * Get whether the current activity is the first time the user is playing it.
 * Must be used within an Activity Context.
 */
export const useFirstLoad = () => {
  const activityContext = useActivityContext();
  const playerData = usePlayerData(activityContext.activityId);
  return playerData?.firstPlay ?? false;
};

/**
 * Get the end data of the current activity. 
 * Must be used within an Activity Context.

 */
export const useActivityEndData = () => {
  const activityContext = useActivityContext();
  console.log(activityContext);
  return activityContext.endData;
};

/**
 * Get the player data for a certain activity from the player profile activity metrics
 */
export const usePlayerData = (activityId: ActivityId) => {
  console.log("Use player data called");
  const { data } = useProfileQuery();

  console.log("data: ", data);

  if (!data?.profile?.activityMetrics) return null;

  const activityMetrics = data.profile.activityMetrics.find(
    (x) => x.activityId === activityId
  );

  if (!activityMetrics) return { firstPlay: true };

  return {
    firstPlay: activityMetrics.numberPlays === 0,
  };
};

export const getActivityMetrics = (
  userProfile: UserProfile,
  activityId: ActivityId
) => {
  if (!userProfile?.profile?.activityMetrics) return null;

  const activityMetrics = userProfile.profile.activityMetrics.find(
    (x) => x.activityId === activityId
  );

  return activityMetrics;
};
