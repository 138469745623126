import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container } from "@inlet/react-pixi";
import { mapToggled } from "Store/Slices/app";
import { useTranslation } from "Main/Hooks/useTranslation";

import map_background from "./Media/map-nocompass.png?as=webp";

import MapItem from "Main/Components/PixiComponents/MapItem/MapItem";
import MapItemTooltip from "Main/Components/PixiComponents/MapItem/MapItemTooltip";

import { getY } from "Main/Utils/pointOperations";
import { mapItems, resources as mapItemResources } from "./mapData";
import Background from "Main/Components/PixiComponents/Background";

const MapContainerOptions = {
  width: 1300,
  height: 750,
  pos: [0, 0],
};

export const resources = {
  map_background,
  ...mapItemResources,
};

const Map = (props) => {
  let history = useHistory();
  const { t } = useTranslation(["mapLocations"]);

  return (
    <Container {...MapContainerOptions}>
      <Background img={map_background} />

      {mapItems.map((mapItem) => (
        <MapItem
          key={mapItem.key}
          img={mapItem.img}
          imgHover={mapItem.img_hover}
          pos={mapItem.pos}
          scale={mapItem.scale}
          onClick={() => {
            history.push("/" + mapItem.location);
            props.mapToggled();
          }}
          tooltip={
            <MapItemTooltip
              positioning={getY(mapItem.pos) > 520 ? "up" : "down"}
              pos={mapItem.pos}
              title={t(`${mapItem.key}.name`)}
              description={t(`${mapItem.key}.description`)}
            />
          }
          adjust={mapItem.adjust}
        ></MapItem>
      ))}
    </Container>
  );
};

const mapStateToProps = (state) => ({
  mapOpen: state.ui.app.mapOpen,
});

const mapDispatchToProps = (dispatch) => ({
  mapToggled: (payload) => dispatch(mapToggled(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Map);
