// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-controls-wrapper {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0px;

  padding: 0px 20px;
}

.video-controls-button {
  width: 50px;
  height: 50px;

  cursor: pointer;
}

.video-controls-image {
  opacity: 0.7;
}

.video-controls-image:hover {
  opacity: 0.85;
}
`, "",{"version":3,"sources":["webpack://./src/Main/Apps/Locations/Ararat/Ararat.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,WAAW;;EAEX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,YAAY;;EAEZ,eAAe;AACjB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":[".video-controls-wrapper {\n  width: 100%;\n  height: 50px;\n  position: absolute;\n  bottom: 0px;\n\n  padding: 0px 20px;\n}\n\n.video-controls-button {\n  width: 50px;\n  height: 50px;\n\n  cursor: pointer;\n}\n\n.video-controls-image {\n  opacity: 0.7;\n}\n\n.video-controls-image:hover {\n  opacity: 0.85;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
