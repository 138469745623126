import React, { useCallback } from "react";
import { connect } from "react-redux";
import { setStageHTMLOverlay } from "Store/Slices/app";

const HTMLContextRoot = (props) => {
  const stageHTMLOverlay = useCallback(
    (node) => {
      if (node) {
        props.setStageHTMLOverlay("stageHTMLOverlayRoot");
      }
    },
    [props]
  );

  return (
    <div
      ref={stageHTMLOverlay}
      id="stageHTMLOverlayRoot"
      style={{
        width: 1300, // TODO: Use reactive Stage Size!
        height: 750,
        position: "absolute",
        pointerEvents: "none",
      }}
    />
  );
};

const mapDispatchToProps = (dispatch) => ({
  setStageHTMLOverlay: (payload) => dispatch(setStageHTMLOverlay(payload)),
});

export default connect(null, mapDispatchToProps)(HTMLContextRoot);
