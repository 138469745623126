import React, { useState, FunctionComponent, useRef } from "react";

import { Container, Sprite, _ReactPixi } from "@inlet/react-pixi";
import { Texture, Container as PixiContainer } from "pixi.js";

import SpriteWithAlphaInteract from "Main/Components/PixiComponents/SpriteWithAlphaInteract";
import { FreeTransformToolInstance } from "Main/Components/TransformTool/TransformTool";
import { getX, getY } from "Main/Utils/pointOperations";

export interface ButtonImageProps extends _ReactPixi.Container<PixiContainer> {
  img?: string;
  imgHover?: string;
  texture?: Texture;
  textureHover?: Texture;
  pos: _ReactPixi.PointLike;
  scale?: _ReactPixi.PointLike;
  width?: number;
  height?: number;
  scaleOnHover?: _ReactPixi.PointLike;
  adjust?: boolean;
  pixelPerfectInteraction?: boolean;
  showChildrenOnlyOnHover?: boolean;
}

const ButtonImage: FunctionComponent<ButtonImageProps> = (props) => {
  const {
    img,
    texture,
    pos,
    width,
    height,
    scale = 1,
    scaleOnHover = 1,
    adjust = false,
    pixelPerfectInteraction = false,
    showChildrenOnlyOnHover = true,
    pointerover,
    pointerout,
    pointertap,
    anchor,
    ...containerProps
  } = props;

  const imgHover = props.imgHover || props.img;
  const textureHover = props.textureHover || props.texture;

  const container = useRef(null);

  const [hover, setHover] = useState(false);

  const ChosenSprite = pixelPerfectInteraction
    ? SpriteWithAlphaInteract
    : Sprite;

  let sizeProps: {
    width?: number;
    height?: number;
    scale?: _ReactPixi.PointLike;
  } = {};
  if (height && width) {
    sizeProps.width = width * (hover ? getX(scaleOnHover) : 1);
    sizeProps.height = height * (hover ? getY(scaleOnHover) : 1);
  } else {
    sizeProps.scale = hover ? scaleOnHover : 1;
  }

  const sourceProp: {
    image?: string;
    texture?: Texture;
  } = {};
  if (img) sourceProp.image = hover ? imgHover : img;
  if (texture) sourceProp.texture = hover ? textureHover : texture;

  return (
    <Container
      position={pos}
      scale={scale}
      interactive={true}
      buttonMode={true}
      pointerover={(e) => {
        if (adjust) return;
        setHover(true);
        if (props.pointerover) props.pointerover(e);
      }}
      pointerout={(e) => {
        if (adjust) return;
        setHover(false);
        if (props.pointerout) props.pointerout(e);
      }}
      pointertap={(e) => {
        if (adjust) {
          FreeTransformToolInstance.select(container.current);
        } else {
          if (props.pointertap) props.pointertap(e);
        }
      }}
      ref={container}
      {...containerProps}
    >
      <ChosenSprite anchor={anchor || 0.5} {...sizeProps} {...sourceProp} />
      {(!showChildrenOnlyOnHover || hover) && props.children}
    </Container>
  );
};

export default ButtonImage;
