import React from "react";
import { Router } from "react-router-dom";
import { createHashHistory } from "history";

// Need to create our own history object so we can create two Router's that share a history
const history = createHashHistory();

const CustomRouter = (props) => {
  return <Router history={history}>{props.children}</Router>;
};

export default CustomRouter;
