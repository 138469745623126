import React from "react";
import ButtonImage from "./ButtonImage";

import { useHistory } from "react-router-dom";

import backButton from "Main/Media/buttons/back.png";

export const resources = {
  backButton,
};

const buttonSize = 75;
const buttonMargin = 25;

const ButtonImageOptions = {
  pos: { x: buttonSize / 2 + buttonMargin, y: buttonSize / 2 + buttonMargin },
  img: backButton,
  imgHover: backButton,
  width: buttonSize,
  height: buttonSize,
  scaleOnHover: 1.1,
};

interface IBackButton {
  previousLocation?: string;
  onGoBack?: () => void;
}

const BackButton: React.FC<IBackButton> = (props) => {
  const history = useHistory();

  const onBack = () => {
    if (props.onGoBack) props.onGoBack();
    else if (props.previousLocation) history.replace(props.previousLocation);
    else history.goBack();
  };

  return <ButtonImage {...ButtonImageOptions} pointertap={onBack} />;
};

export default BackButton;
