import generic_en from "Translations/en/generic.json";
import generic_hy from "Translations/hy/generic.json";

import dictionary_en from "Translations/en/dictionary.json";
import dictionary_hy from "Translations/hy/dictionary.json";

import mapLocations_en from "Translations/en/mapLocations.json";
import mapLocations_hy from "Translations/hy/mapLocations.json";

import toolbar_en from "Translations/en/toolbar.json";
import toolbar_hy from "Translations/hy/toolbar.json";

import wikiArtsakhWar_en from "Translations/en/wiki/artsakhWar.json";
import wikiArtsakhWar_hy from "Translations/hy/wiki/artsakhWar.json";

import mapGame_en from "Translations/en/mapGame.json";
import mapGame_hy from "Translations/hy/mapGame.json";

import artsakhMapData_en from "Translations/en/artsakh/artsakhMapData.json";
import artsakhMapData_hy from "Translations/hy/artsakh/artsakhMapData.json";

import artsakhMapGame_en from "Translations/en/artsakh/artsakhMapGame.json";
import artsakhMapGame_hy from "Translations/hy/artsakh/artsakhMapGame.json";

import artsakhHistory_en from "Translations/en/artsakh/artsakhHistory.json";
import artsakhHistory_hy from "Translations/hy/artsakh/artsakhHistory.json";

import artsakhFAQ_hy from "Translations/hy/artsakh/artsakhFAQ.json";

import shoppingFlashCards_hy from "Translations/hy/flashCards/shopping.json";

import shoppingFlashCards2_en from "Translations/en/flashCards/shopping2.json";
import shoppingFlashCards2_hy from "Translations/hy/flashCards/shopping2.json";

import dilijanZoo_en from "Translations/en/activities/dilijanZoo.json";
import dilijanZoo_hy from "Translations/hy/activities/dilijanZoo.json";

//import artsakhMapGame_en from "Translations/en/artsakhMapGame.json";
//import artsakhMapGame_hy from "Translations/hy/artsakhMapGame.json";

import iceCreamOrderUp_en from "Translations/en/activities/iceCreamOrderUp.json";

import iceCreamAlphabet_en from "Translations/en/activities/iceCreamAlphabet.json";

import groceryGame_en from "Translations/en/activities/groceryGame.json";
import groceryGame_hy from "Translations/hy/activities/groceryGame.json";

const translations = {
  en: {
    generic: generic_en,
    dictionary: dictionary_en,
    mapLocations: mapLocations_en,
    toolbar: toolbar_en,
    "wiki.artsakhWar": wikiArtsakhWar_en,
    mapGame: mapGame_en,
    artsakhMapData: artsakhMapData_en,
    artsakhMapGame: artsakhMapGame_en,
    artsakhHistory: artsakhHistory_en,
    "flashCards.shopping2": shoppingFlashCards2_en,
    "activities.iceCreamOrderUp": iceCreamOrderUp_en,
    "activities.iceCreamAlphabet": iceCreamAlphabet_en,
    "activities.groceryGame": groceryGame_en,
    "activities.dilijanZoo": dilijanZoo_en,
  },
  hy: {
    generic: generic_hy,
    dictionary: dictionary_hy,
    mapLocations: mapLocations_hy,
    toolbar: toolbar_hy,
    "wiki.artsakhWar": wikiArtsakhWar_hy,
    mapGame: mapGame_hy,
    artsakhMapData: artsakhMapData_hy,
    artsakhMapGame: artsakhMapGame_hy,
    artsakhHistory: artsakhHistory_hy,
    artsakhFAQ: artsakhFAQ_hy,
    "flashCards.shopping": shoppingFlashCards_hy,
    "flashCards.shopping2": shoppingFlashCards2_hy,
    "activities.groceryGame": groceryGame_hy,
    "activities.dilijanZoo": dilijanZoo_hy,
  },
};

export default translations;
