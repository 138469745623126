import { useCallback } from "react";
import { useTranslation as useT, TFunction } from "react-i18next";

type NameSpaceList = string | string[] | NameSpaceList[];

export const useTranslation = (ns?: NameSpaceList) => {
  const flatNs = ns ? flatten(ns) : undefined;
  const [T] = useT(flatNs);

  const t = useCallback(
    (key: string, options?: any) => {
      return T(key, { ...options, ns: flatNs });
    },
    [T, flatNs]
  );

  return { t } as { t: TFunction<string[]> };
};

const flatten = (ns: NameSpaceList) => {
  let flat: string[] = [];
  if (Array.isArray(ns)) {
    // @ts-ignore
    flat = ns.flat(Infinity) as string[];
  } else if (typeof ns === "string" || (ns as any) instanceof String) {
    flat = [ns];
  }

  return flat;
};
