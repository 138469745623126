import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface GameEndData {
  clock: number;
  win: boolean;
  score: number;
}

interface PlayerGameData {
  firstPlay: boolean;
}

interface GameData {
  name: number;
  playing: boolean;
  ended: boolean;
  endData?: GameEndData;
  playerData?: PlayerGameData;
}

interface GameState {
  currentGame: GameData | null;
}

const initialState: GameState = {
  currentGame: null,
};

const slice = createSlice({
  name: "game",
  initialState,
  reducers: {
    gameStarted: (state, action: PayloadAction<any>) => {
      state.currentGame = {
        name: action.payload,
        playing: true,
        ended: false,
        playerData: { firstPlay: true }, // TODO: fetch data from server
      };
    },
    gamePaused: (state) => {
      if (state.currentGame) {
        state.currentGame.playing = false;
      }
    },
    gameUnpaused: (state) => {
      if (state.currentGame) {
        state.currentGame.playing = true;
      }
    },
    gameEnded: (state, action: PayloadAction<GameEndData>) => {
      if (state.currentGame) {
        state.currentGame.ended = true;
        state.currentGame.endData = action.payload;
      }
    },
    clearGame: (state) => {
      state.currentGame = null;
    },
  },
});

export default slice.reducer;

export const {
  gameStarted,
  gamePaused,
  gameUnpaused,
  gameEnded,
  clearGame,
} = slice.actions;
