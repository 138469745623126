import React from "react";
import "./LoadingScreen.css";

const LoadingScreen = (props) => {
  return (
    <div className="loadingscreen-background">
      Loading... <br />
      {props.progress.toFixed(0)}%
    </div>
  );
};

export default LoadingScreen;
