import React, { useEffect, useState, useRef, useCallback } from "react";
import ReactDOM from "react-dom";

import { connect, Provider } from "react-redux";
import { useAppSelector } from "Main/Hooks/redux";

import store from "Store/store";

import "./HTMLContext.css";

const HTMLContext = (props) => {
  const { receivePointerEvents = false, showOnMap = false } = props;

  const mapOpen = useAppSelector((state) => state.ui.app.mapOpen);

  const [div] = useState(() => document.createElement("div"));

  const statefulComponent = useRef(null);

  // Create / Cleanup the div that we use for the HTML Context
  // The div is mounted as a child of the HTMLContextRoot
  useEffect(() => {
    if (props.stageHTMLOverlay) {
      document.getElementById(props.stageHTMLOverlay).appendChild(div);

      // Don't actually render the div.
      div.classList.add("html-context");

      if (receivePointerEvents)
        div.classList.add("html-context-receive-pointer-events");
    }

    return () => {
      if (props.stageHTMLOverlay)
        document.getElementById(props.stageHTMLOverlay)?.removeChild(div);
    };
  }, [props.stageHTMLOverlay, div, receivePointerEvents]);

  useEffect(() => {
    if (mapOpen && !showOnMap) {
      div.style = "display: none";
    } else {
      div.style = "";
    }
  }, [mapOpen, div, showOnMap]);

  const wrapInProviders = useCallback(
    (children) => {
      let node = children;

      for (let i = 0; i < props.extraProviders?.length || 0; i++) {
        const [ExtraProvider, value] = props.extraProviders[i];
        node = <ExtraProvider value={value}>{node}</ExtraProvider>;
      }

      return <Provider store={store}>{node}</Provider>;
    },
    [props.extraProviders]
  );

  useEffect(() => {
    if (statefulComponent.current) {
      statefulComponent.current.setChildren(wrapInProviders(props.children));
    }
  }, [props.children, wrapInProviders]);

  // Render / Unmount the children of this node into the div we just created
  useEffect(() => {
    if (props.stageHTMLOverlay)
      ReactDOM.render(
        <StatefulComponent ref={statefulComponent}>
          {wrapInProviders(props.children)}
        </StatefulComponent>,
        div
      );

    return () => {
      ReactDOM.unmountComponentAtNode(div);
    };
    // eslint-disable-next-line
  }, [props.stageHTMLOverlay, div]);

  return null;
};

export const ReceivePointerEvents = (props) => {
  return (
    <div className="html-context-receive-pointer-events">{props.children}</div>
  );
};

// We need a stateful component to make ReactDOM.render() update the Dom tree with the children
class StatefulComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { children: props.children };
  }

  setChildren(children) {
    this.setState({ children });
  }

  render() {
    return this.state.children;
  }
}

const mapStateToProps = (state) => ({
  stageHTMLOverlay: state.ui.app.stageHTMLOverlay,
});

export default connect(mapStateToProps)(HTMLContext);
