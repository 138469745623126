// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-summary {
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 8px;
  padding-top: 3px;
  padding-bottom: 4px;
  border-top: 1px solid grey;
}

.tooltip-icons-wrap {
  margin-top: 12px;
}

.tooltip-icon {
  padding-left: 6px;
  padding-right: 6px;
  height: 40px;
  width: 40px;
}

.tooltip-wrapper {
  color: black;
  font-weight: 500;
  background-color: #d3d3d3;
  opacity: 0.9;
  border-radius: 10px;
  padding: 5px;
  text-align: center;
  width: 260px;
  height: 145px;
  margin-top: 90px;
}
`, "",{"version":3,"sources":["webpack://./src/Main/Components/PixiComponents/MapItem/ButtonImage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;EACnB,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".tooltip-summary {\n  font-size: 20px;\n  margin-top: 4px;\n  margin-bottom: 8px;\n  padding-top: 3px;\n  padding-bottom: 4px;\n  border-top: 1px solid grey;\n}\n\n.tooltip-icons-wrap {\n  margin-top: 12px;\n}\n\n.tooltip-icon {\n  padding-left: 6px;\n  padding-right: 6px;\n  height: 40px;\n  width: 40px;\n}\n\n.tooltip-wrapper {\n  color: black;\n  font-weight: 500;\n  background-color: #d3d3d3;\n  opacity: 0.9;\n  border-radius: 10px;\n  padding: 5px;\n  text-align: center;\n  width: 260px;\n  height: 145px;\n  margin-top: 90px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
