import { ActivityId } from "Main/Components/Activity/activityId";
import { api } from "./api";

type UserPreferences = {
  preferredLanguage: "hy" | "en";
};

type UserActivityMetric = {
  activityId: ActivityId;
  numberPlays: number;
  numberWins: number;
  totalBananasWon: number;
  totalTimePlayed: number;
  lastPlayed: string;
};

export type UserProfile = {
  preferences: UserPreferences;
  profile: {
    bananas: number;
    activityMetrics: UserActivityMetric[];
  };
};

export const usersApi = api.injectEndpoints({
  endpoints: (build) => ({
    profile: build.query<UserProfile, void>({
      query: () => `/user/profile`,
      providesTags: ["profile"],
    }),
    updatePreferences: build.mutation<UserPreferences, UserPreferences>({
      query: (preferences) => ({
        url: `/user/preferences`,
        method: "PUT",
        body: preferences,
      }),
      onQueryStarted: ({ ...patch }, { dispatch, queryFulfilled }) => {
        dispatch(
          usersApi.util.updateQueryData("profile", undefined, (draft) =>
            Object.assign(draft, { preferences: patch })
          )
        );

        queryFulfilled.catch((e) => {
          dispatch(usersApi.util.invalidateTags(["profile"]));
        });
      },
    }),
  }),
});

export const { useProfileQuery, useUpdatePreferencesMutation } = usersApi;
