import { getX, getY } from "Main/Utils/pointOperations";
import * as PIXI from "pixi.js";

// helpers
export function calcAngleDegrees(x, y) {
  return (calcAngleRadians(x, y) * 180) / Math.PI;
}

export function calcAngleRadians(x, y) {
  return Math.atan2(y, x);
}

export function calcDistance(a, b) {
  return Math.sqrt(
    Math.pow(getX(b) - getX(a), 2) + Math.pow(getY(b) - getY(a), 2)
  );
}

/**
 * Force a rectangle to always be inside another by
 * updating location and size.
 * @param {PIXI.Rectangle} rect
 * @param {PIXI.Rectangle} container
 */
export function constrainRectTo(rect, container, debug) {
  if (rect.width >= container.width) {
    rect.width = container.width;
    if (debug) {
      console.log("constraining width to", rect.width);
    }
  }
  if (rect.x <= container.x) {
    rect.x = container.x;
    if (debug) {
      console.log("constraining x left at", rect.x);
    }
  } else if (rect.x + rect.width > container.x + container.width) {
    rect.x = container.x + container.width - rect.width;
    if (debug) {
      console.log("constraining x right at", rect.x + rect.width);
    }
  }
  if (rect.height >= container.height) {
    rect.height = container.height;
    if (debug) {
      console.log("constraining height to", rect.height);
    }
  }
  if (rect.y <= container.y) {
    rect.y = container.y;
    if (debug) {
      console.log("constraining y top to", rect.y);
    }
  } else if (rect.y + rect.height > container.y + container.height) {
    rect.y = container.y + container.height - rect.height;
    if (debug) {
      console.log("constraining y bottom to", rect.y + rect.height);
    }
  }
  return rect;
}

// constrains a display object to a given rect
export function constrainObjectTo(obj, container) {
  var bounds = obj.getBounds();
  // bounds.x = obj.x - (obj.regX * obj.scale.x);
  // bounds.y = obj.y - (obj.regY * obj.scale.y);
  var constrained = new PIXI.Rectangle(
    bounds.x,
    bounds.y,
    bounds.width,
    bounds.height
  );
  constrainRectTo(constrained, container);
  var delta = {
    x: bounds.x - constrained.x,
    y: bounds.y - constrained.y,
  };

  // TODO: work out new scale to apply, rather than overlapping
  var newScale = Math.min(
    constrained.width / bounds.width,
    constrained.height / bounds.height
  );

  obj.x = obj.x - delta.x;
  obj.y = obj.y - delta.y;
  // obj.x = obj.position.x + delta.x;
  // obj.y = obj.position.y + delta.y;
  obj.scale.x *= newScale;
  obj.scale.y *= newScale;
}
