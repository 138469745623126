import React, { FunctionComponent, useCallback, useEffect } from "react";
import { Container } from "@inlet/react-pixi";
import { Container as PIXIContainer } from "pixi.js";
import { FreeTransformTool } from "./pixijsFreeTransformTool";

type TransformToolProps = {
  selected: PIXIContainer;
};

export let FreeTransformToolInstance: FreeTransformTool;

const ContainerProps = {
  width: 1300,
  height: 750,
};

const TransformTool: FunctionComponent<TransformToolProps> = (props) => {
  useEffect(() => {
    if (props.selected) FreeTransformToolInstance.select(props.selected);
    else FreeTransformToolInstance.unselect();
  }, [props.selected]);

  const onContainerMount = useCallback((container: PIXIContainer) => {
    if (container) {
      FreeTransformToolInstance = new FreeTransformTool();
      container.addChild(FreeTransformToolInstance);
    }
  }, []);

  return <Container {...ContainerProps} ref={onContainerMount}></Container>;
};

export default TransformTool;
