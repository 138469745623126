// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./media/passport.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-toolbar {
  padding: 20px;
  color: #113166;
  font-family: Helvetica, Arial, sans-serif;
}

.ui-modal-background {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: 100% 100%;
  background-position: center;
  width: 950px;
  height: 600px;
}

.ui-modal-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 50px 1fr;
  grid-template-areas: "title-left title-right" "content-left content-right";
  width: 100%;
  height: 100%;
}

.ui-modal-title-left {
  grid-area: title-left;
  line-height: 50px;
}

.ui-modal-title-right {
  grid-area: title-right;
  line-height: 50px;
}

.ui-modal-content-left {
  grid-area: content-left;
}

.ui-modal-content-right {
  grid-area: content-right;
}
`, "",{"version":3,"sources":["webpack://./src/Main/Components/UIModal/UIModal.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,cAAc;EACd,yCAAyC;AAC3C;;AAEA;EACE,yDAA6C;EAC7C,0BAA0B;EAC1B,2BAA2B;EAC3B,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,4BAA4B;EAC5B,0EAA0E;EAC1E,WAAW;EACX,YAAY;AACd;;AAEA;EACE,qBAAqB;EACrB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,iBAAiB;AACnB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;AAC1B","sourcesContent":[".modal-toolbar {\n  padding: 20px;\n  color: #113166;\n  font-family: Helvetica, Arial, sans-serif;\n}\n\n.ui-modal-background {\n  background-image: url(\"./media/passport.png\");\n  background-size: 100% 100%;\n  background-position: center;\n  width: 950px;\n  height: 600px;\n}\n\n.ui-modal-content {\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-template-rows: 50px 1fr;\n  grid-template-areas: \"title-left title-right\" \"content-left content-right\";\n  width: 100%;\n  height: 100%;\n}\n\n.ui-modal-title-left {\n  grid-area: title-left;\n  line-height: 50px;\n}\n\n.ui-modal-title-right {\n  grid-area: title-right;\n  line-height: 50px;\n}\n\n.ui-modal-content-left {\n  grid-area: content-left;\n}\n\n.ui-modal-content-right {\n  grid-area: content-right;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
