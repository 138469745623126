import { Texture } from "pixi.js";

export const getTextureFromProps = (elementType, props = {}) => {
  const emitChange = () =>
    requestAnimationFrame(() => {
      window.dispatchEvent(new CustomEvent("__REACT_PIXI_REQUEST_RENDER__"));
    });

  const check = (inType, validator) => {
    if (props.hasOwnProperty(inType)) {
      const valid =
        validator.typeofs.some((t) => typeof props[inType] === t) ||
        validator.instanceofs.some((i) => props[inType] instanceof i);
      invariant(valid, `${elementType} ${inType} prop is invalid`);
      return props[inType];
    }
  };

  if (props.texture) {
    invariant(
      props.texture instanceof Texture,
      `${elementType} texture needs to be typeof \`PIXI.Texture\``
    );
    return props.texture;
  } else {
    const result =
      check("image", {
        typeofs: ["string"],
        instanceofs: [HTMLImageElement],
      }) ||
      check("video", {
        typeofs: ["string"],
        instanceofs: [HTMLVideoElement],
      }) ||
      check("source", {
        typeofs: ["string", "number"],
        instanceofs: [
          HTMLImageElement,
          HTMLVideoElement,
          HTMLCanvasElement,
          Texture,
        ],
      });

    invariant(!!result, `${elementType} could not get texture from props`);

    const texture = Texture.from(result);
    texture.once("update", emitChange);
    texture.once("loaded", emitChange);

    if (texture.valid) {
      emitChange();
    }

    return texture;
  }
};

function invariant(condition, format, ...args) {
  if (process.env.NODE_ENV === "production") {
    return;
  }

  if (!condition) {
    let error;
    if (format === undefined) {
      error = new Error(
        "Minified exception occurred; use the non-minified dev environment " +
          "for the full error message and additional helpful warnings."
      );
    } else {
      let argIndex = 0;
      error = new Error(format.replace(/%s/g, () => String(args[argIndex++])));
      error.name = "Invariant Violation";
    }

    error.framesToPop = 1; // Skip invariant's own stack frame.
    throw error;
  }
}
