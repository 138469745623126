import { getX, getY } from "Main/Utils/pointOperations";
import React from "react";

/**
 * Must be used within HTML context
 * Positions the element based on PIXI position
 * @param position [x, y] React.PointLike
 * @param anchor [x, y] React.PointLike Anchor (default, [0.5, 0])
 * @param children HTML-context children
 * @returns
 */
const ElementWrapper = ({ children, position, anchor = [0.5, 0] }) => {
  const xTranslatePercentage = (getX(anchor) * 100).toFixed(0);
  const yTranslatePercentage = (getY(anchor) * 100).toFixed(0);

  return (
    <div
      style={{
        position: "absolute",
        top: position[1],
        left: position[0],
        transform: `translateX(-${xTranslatePercentage}%) translateY(-${yTranslatePercentage}%)`,
      }}
    >
      {children}
    </div>
  );
};

export default ElementWrapper;
