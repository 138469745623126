import { useState, useEffect } from "react";
import { Loader } from "pixi.js";

const useLoader = (resources: { [key: string]: string }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loader] = useState(() => new Loader());

  useEffect(() => {
    for (const resource in resources) {
      loader.add(resource, resources[resource]);
    }

    loader.load((loader, resources) => {
      setIsLoaded(true);
    });

    const progressId = loader.onProgress.add((loader) => {
      if (loader.progress === 100) setIsLoaded(true);
      setProgress(loader.progress);
    });

    return () => {
      loader.onProgress.detach(progressId);
      loader.destroy();
    };
  }, [resources, loader]);

  return [isLoaded, progress, loader] as const;
};

export default useLoader;
