import React, { FC } from "react";

import { setUIModalOpen } from "Store/Slices/app";
import { logout } from "Store/Slices/user";
import { useAppSelector, useAppDispatch } from "Main/Hooks/redux";
import Modal from "../Modals/Modal";
import "./UIModal.css";

import {
  useProfileQuery,
  useUpdatePreferencesMutation,
} from "Store/Services/users";

const ToolbarModal: FC = (props) => {
  const fullscreen = useAppSelector((state) => state.ui.app.fullscreen);
  const currentUser = useAppSelector((state) => state.ui.user.user);

  const dispatch = useAppDispatch();

  const { data: userProfile } = useProfileQuery();

  const [updateUserPreferences] = useUpdatePreferencesMutation();

  const changeLanguage = () => {
    if (!userProfile) return;
    if (userProfile.preferences.preferredLanguage === "en") {
      updateUserPreferences({ preferredLanguage: "hy" });
    } else if (userProfile.preferences.preferredLanguage === "hy") {
      updateUserPreferences({ preferredLanguage: "en" });
    }
  };

  const onFullscreenChange = () => {
    if (!fullscreen)
      document.documentElement.requestFullscreen().catch((e) => {});
    if (fullscreen) document.exitFullscreen().catch((e) => {});
  };

  return (
    <Modal showOnMap close={() => dispatch(setUIModalOpen(false))}>
      <div className="modal-toolbar">
        <div className="ui-modal-background">
          <div className="ui-modal-content">
            <div className="ui-modal-title-left">{currentUser?.username}</div>
            <div className="ui-modal-content-left">Picture of Gabig</div>
            <div className="ui-modal-title-right">Settings</div>
            <div className="ui-modal-content-right">
              <div>
                <p>Change Language:</p>
                <button
                  onClick={() => {
                    changeLanguage();
                  }}
                >
                  {!userProfile?.preferences
                    ? "..."
                    : userProfile.preferences.preferredLanguage === "en"
                    ? "Armenian"
                    : "English"}
                </button>
              </div>
              <div>
                <p>Fullscreen:</p>
                <button onClick={onFullscreenChange}>
                  {fullscreen
                    ? "Exit Fullscreen Mode"
                    : "Enter Fullscreen Mode"}
                </button>
              </div>
              <div>
                {/* @ts-ignore */}
                <button onClick={() => dispatch(logout())}>Log Out</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ToolbarModal;
