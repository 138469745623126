import React, { FC, useState } from "react";
import { useAppDispatch, useAppSelector } from "Main/Hooks/redux";
import { loginWithCredentials } from "Store/Slices/user";

const Login: FC = (props) => {
  const dispatch = useAppDispatch();
  const loginError = useAppSelector((state) => state.ui.user.loginError);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const onLogin = () => {
    dispatch(
      // @ts-ignore
      loginWithCredentials({
        username,
        password,
      })
    );
  };

  return (
    <div>
      <div>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          placeholder="Username..."
        />
      </div>
      <div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password..."
        />
      </div>
      <button onClick={onLogin}>Login</button>
      {loginError === "LOGIN_FAILED" && (
        <span style={{ color: "red" }}>Login Failed. Please Try Again.</span>
      )}
      <button>Forgot Password...</button>
    </div>
  );
};

export default Login;
