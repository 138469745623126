import React, { FC, useRef, useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "Main/Hooks/redux";
import { setFullscreen } from "Store/Slices/app";

import "./AppResizer.css";

const calculateScale = (node: Element | null, verticalMenu: boolean) => {
  let width, height;
  if (node === null) {
    width = window.innerWidth;
    height = window.innerHeight;
  } else {
    width = node.clientWidth;
    height = node.clientHeight;
  }

  const desiredWidth = 1300 + (verticalMenu ? 140 : 0);
  const desiredHeight = 750 + (!verticalMenu ? 97 : 0);
  return Math.min(width / desiredWidth, height / desiredHeight);
};

const AppResizer: FC = (props) => {
  const fullscreen = useAppSelector((state) => state.ui.app.fullscreen);
  const verticalMenu = useAppSelector((state) => state.ui.app.verticalMenu);
  const dispatch = useAppDispatch();

  const appContainer = useRef(null);
  const [scale, setScale] = useState(calculateScale(null, verticalMenu));
  const [forceMobileFullscreen, setForceMobileFullscreen] = useState(false);

  useEffect(() => {
    setScale(calculateScale(appContainer.current, verticalMenu));
    const handleResize = () => {
      setScale(calculateScale(appContainer.current, verticalMenu));
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [appContainer, fullscreen, verticalMenu]);

  // Query for fullscreen mode (Main App Fullscreen selector)
  useEffect(() => {
    const handler = (event: MediaQueryListEvent) => {
      dispatch(setFullscreen(event.matches));
    };

    const query = matchMedia(
      "(display-mode: fullscreen) or (display-mode: standalone) or (display-mode: minimal-ui) or (display-mode: window-controls-overlay)"
    );
    if (query.matches) dispatch(setFullscreen(true));

    query.addEventListener("change", handler);

    return () => query.removeEventListener("change", handler);
  });

  useEffect(() => {
    // Matches iPhones to force pseudo-fullscreen mode
    const query = matchMedia(
      "(max-device-height: 430px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape), " +
        "(max-device-width: 430px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait)"
    );
    if (query.matches) setForceMobileFullscreen(true);

    const handler = (event: MediaQueryListEvent) => {
      setForceMobileFullscreen(event.matches);
    };

    query.addEventListener("change", handler);

    return () => query.removeEventListener("change", handler);
  }, [setForceMobileFullscreen]);

  return (
    <div
      className={
        "app-container-padding " +
        (fullscreen || forceMobileFullscreen ? "fullscreen" : "not-fullscreen")
      }
    >
      <div className="app-container" ref={appContainer}>
        <div
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top",
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AppResizer;
