import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

export const getErrorStatusCode = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  if (!error) return undefined;

  if ("status" in error) {
    return error.status;
  }

  return undefined;
};
