import React from "react";
import ReactDOM from "react-dom";
import "./Fonts/fonts.css";
import "./index.css";
import "./i18n";
import WebFont from "webfontloader";
import { gsap } from "gsap";
import { PixiPlugin } from "gsap/PixiPlugin";
import * as PIXI from "pixi.js";

import { Amplify } from "aws-amplify";
import awsconfig from "./aws-exports";
import { Providers } from "./Main/Providers";
import { AppLoginRouter } from "./Main/AppLoginRouter";

Amplify.configure(awsconfig);

gsap.registerPlugin(PixiPlugin);
PixiPlugin.registerPIXI(PIXI);
PIXI.Application.registerPlugin(PIXI.TickerPlugin);
// PIXI.extensions.add(PIXI.TickerPlugin);
console.log(PIXI);

WebFont.load({
  custom: {
    families: ["DejaVu"],
    urls: ["/Fonts/fonts.css"],
    testStrings: {
      DejaVu: "\u0530",
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <div id="main">
        <div id="left-leaves" />
        <div id="right-leaves" />

        <AppLoginRouter />

        <div className="copyright-text">Copyright ABC Gabig 2021</div>
      </div>
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((registration) => {
        console.log("SW registered: ", registration);
      })
      .catch((registrationError) => {
        console.log("SW registration failed: ", registrationError);
      });
  });
}
