import React, { FunctionComponent, ReactNode } from "react";

import ButtonImage from "../ButtonImage";
import { _ReactPixi } from "@inlet/react-pixi";

type MapItemProps = {
  img: string;
  imgHover?: string;
  pos: _ReactPixi.PointLike;
  onClick: () => void;
  scale: _ReactPixi.PointLike;
  scaleOnHover?: _ReactPixi.PointLike;
  adjust?: boolean;
  tooltip?: ReactNode;
};

const MapItem: FunctionComponent<MapItemProps> = (props) => {
  const {
    img,
    pos,
    onClick,
    scale = 1,
    scaleOnHover = 1.08,
    adjust = false,
    tooltip,
  } = props;
  const imgHover = props.imgHover || props.img;

  return (
    <ButtonImage
      img={img}
      imgHover={imgHover}
      pos={pos}
      scale={scale}
      width={150}
      height={150}
      scaleOnHover={scaleOnHover}
      pointertap={onClick}
      adjust={adjust}
    >
      {tooltip}
    </ButtonImage>
  );
};

export default MapItem;
