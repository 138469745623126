// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Main/Media/leaves-strip.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body,
#root,
#main {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
}

body {
  margin: 0;
  font-family: "DejaVu", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#main {
  background-color: #001c44;
}

#left-leaves,
#right-leaves {
  height: 100%;
  width: calc(50% - 40px);
  left: 0px;
  position: absolute;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) left 0 no-repeat;
  background-repeat: repeat-y;
  background-size: contain;
  opacity: 0.6;
}

#right-leaves {
  left: unset;
  right: 0px;
  transform: rotate(180deg);
}

.copyright-text {
  color: whitesmoke;
  position: absolute;
  bottom: 10px;
  text-align: center;
  width: 100%;
  font-size: 0.8rem;
  font-weight: 100;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;;;;EAIE,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;EAChB,yBAAiB;UAAjB,iBAAiB;AACnB;;AAEA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE;aACW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;;EAEE,YAAY;EACZ,uBAAuB;EACvB,SAAS;EACT,kBAAkB;EAClB,oEAAkE;EAClE,2BAA2B;EAC3B,wBAAwB;EACxB,YAAY;AACd;;AAEA;EACE,WAAW;EACX,UAAU;EACV,yBAAyB;AAC3B;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":["html,\nbody,\n#root,\n#main {\n  margin: 0;\n  padding: 0;\n  height: 100%;\n  overflow: hidden;\n  user-select: none;\n}\n\nbody {\n  margin: 0;\n  font-family: \"DejaVu\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\",\n    \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n    monospace;\n}\n\n#main {\n  background-color: #001c44;\n}\n\n#left-leaves,\n#right-leaves {\n  height: 100%;\n  width: calc(50% - 40px);\n  left: 0px;\n  position: absolute;\n  background: url(\"./Main/Media/leaves-strip.webp\") left 0 no-repeat;\n  background-repeat: repeat-y;\n  background-size: contain;\n  opacity: 0.6;\n}\n\n#right-leaves {\n  left: unset;\n  right: 0px;\n  transform: rotate(180deg);\n}\n\n.copyright-text {\n  color: whitesmoke;\n  position: absolute;\n  bottom: 10px;\n  text-align: center;\n  width: 100%;\n  font-size: 0.8rem;\n  font-weight: 100;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
