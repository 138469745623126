// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.html-context {
  display: contents;
}

.html-context-receive-pointer-events {
  pointer-events: all;
}
`, "",{"version":3,"sources":["webpack://./src/Main/Components/PixiComponents/HTML/HTMLContext.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".html-context {\n  display: contents;\n}\n\n.html-context-receive-pointer-events {\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
