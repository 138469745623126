const awsconfig = {
  aws_project_region: "us-west-2",
  Auth: {
    region: "us-west-2",
    userPoolId: "us-west-2_XgxtdzCMr",
    userPoolWebClientId: "2ednim8d3m2m8i32hi69246cel",
  },
};

export default awsconfig;
