import React, { FC } from "react";
import { Graphics, Sprite, useApp, _ReactPixi } from "@inlet/react-pixi";

interface IBackground extends _ReactPixi.ISprite {
  img: string;
}

interface IBackgroundColor extends _ReactPixi.IGraphics {
  color: number;
}

const Background: FC<IBackground> = React.forwardRef((props, ref) => {
  const app = useApp();
  const w = app.renderer.width;
  const h = app.renderer.height;

  const { img, ...rest } = props;

  return (
    <Sprite
      image={img}
      ref={ref as any}
      position={[0, 0]}
      width={w}
      height={h}
      {...rest}
    />
  );
});

const BackgroundColor: FC<IBackgroundColor> = React.forwardRef((props, ref) => {
  const app = useApp();
  const w = app.renderer.width;
  const h = app.renderer.height;

  const { color, ...rest } = props;

  return (
    <Graphics
      draw={(g) => {
        g.clear()
          .beginFill(color ?? 0xffffff)
          .drawRect(0, 0, w, h);
      }}
      ref={ref}
      {...rest}
    />
  );
});

export default Background;
export { BackgroundColor };
