import React, { FunctionComponent } from "react";

import { Container } from "@inlet/react-pixi";

import BackButton from "Main/Components/PixiComponents/BackButton";
import ResourceLoader from "Main/Components/PixiComponents/ResourceLoader";
import { useLocationContext } from "Main/Components/Location/locationContext";
import { ActivityId } from "../Activity/activityId";
import { useLogActivityMutation } from "Store/Services/activity";
import { ActivityContextProvider } from "../Activity/activityContext";

const ContainerOptions = {
  width: 1300,
  height: 750,
  position: { x: 0, y: 0 },
};

type LocationProps = {
  hasBackButton: boolean;
  resources: { [key: string]: string };
  logLocationAsActivity?: ActivityId;
};

/**
 * @param logLocationAsActivity ActivityId to log when we enter this location; provides ActiviyContext for the location
 */
const LocationTemplate: FunctionComponent<LocationProps> = (props) => {
  const { hasBackButton, resources } = props;
  const locationContext = useLocationContext();

  const [logActivityMutation] = useLogActivityMutation();

  React.useEffect(() => {
    if (props.logLocationAsActivity) {
      logActivityMutation({
        activityId: props.logLocationAsActivity,
      });
    }
  }, [props.logLocationAsActivity, logActivityMutation]);

  let location = (
    <Container {...ContainerOptions}>
      {props.children}
      {hasBackButton && (
        <BackButton
          onGoBack={locationContext?.onGoBack ?? undefined}
          previousLocation={locationContext?.parentLocation ?? undefined}
        />
      )}
    </Container>
  );

  if (props.logLocationAsActivity) {
    location = (
      <ActivityContextProvider
        value={{
          activityId: props.logLocationAsActivity,
          status: "pregame",
          paused: false,
          setPaused: () => {}, //NoOp
        }}
      >
        {location}
      </ActivityContextProvider>
    );
  }

  if (!resources) return location;

  return <ResourceLoader resources={resources}>{location}</ResourceLoader>;
};

export default LocationTemplate;
