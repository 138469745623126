import React, { FC } from "react";
import "./Modal.css";
import HTMLContext from "Main/Components/PixiComponents/HTML/HTMLContext";
import closeButton from "Main/Media/buttons/small-x.png";

interface ModalProps {
  close: () => void;
  backgroundColor?: string;
  backgroundImage?: string;
  width?: number;
  height?: number;
  showOnMap?: boolean;
}

const Modal: FC<ModalProps> = (props) => {
  const showOnMap = props.showOnMap || false;
  const modalBoxStyles: React.CSSProperties = {
    height: "content-fit",
    width: "content-fit",
  };

  if (props.width || props.height) {
    modalBoxStyles.width = `${props.width}px`;
    modalBoxStyles.height = `${props.height}px`;
  }

  modalBoxStyles.background = "transparent";

  if (props.backgroundColor)
    modalBoxStyles.backgroundColor = props.backgroundColor;

  if (props.backgroundImage)
    modalBoxStyles.backgroundImage = `url(${props.backgroundImage})`;

  return (
    <HTMLContext showOnMap={showOnMap} receivePointerEvents>
      <div className="modal-background" onClick={() => props.close()}>
        <div
          className="modal-box"
          style={modalBoxStyles}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {props.children}
          <img
            className="modal-close-button"
            src={closeButton}
            onClick={() => props.close()}
            alt="close modal"
          />
        </div>
      </div>
    </HTMLContext>
  );
};

export default Modal;
