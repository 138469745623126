import { ActivityId } from "Main/Components/Activity/activityId";
import { api } from "./api";
import { usersApi } from "./users";

type BeginActivityParams = {
  activityId: ActivityId;
};

type BeginActivityResult = {
  id: string;
  activityId: ActivityId;
  started_at: Date;
};

type EndActivityStatus = "completed" | "aborted";

type AbortActivityParams = Pick<EndActivityParams, "activitySessionId">;

type EndActivityParams = {
  activitySessionId: string;
  status: EndActivityStatus;
  win: boolean;
  bananas: number;
  duration: number;
};

export type ActivitySessionResult = {
  status: EndActivityStatus;
  ended_at: Date;
  win: boolean;
  bananas: number;
  duration: number;
};

type EndActivityResult = BeginActivityResult & {
  activitySessionResult: ActivitySessionResult;
};

export const activityApi = api.injectEndpoints({
  endpoints: (build) => ({
    beginActivity: build.mutation<BeginActivityResult, BeginActivityParams>({
      query: (body) => ({
        url: `/activity/begin`,
        method: "POST",
        body,
      }),
    }),
    abortActivity: build.mutation<EndActivityResult, AbortActivityParams>({
      query: ({ activitySessionId }) => ({
        url: `activity/end`,
        method: "POST",
        body: {
          activitySessionId,
          status: "aborted",
          duration: 0,
          bananas: 0,
          win: false,
        },
      }),
      invalidatesTags: ["profile"],
    }),
    endActivity: build.mutation<EndActivityResult, EndActivityParams>({
      query: (body) => ({
        url: `/activity/end`,
        method: "POST",
        body,
      }),

      // Optimistic Update on profile bananas
      onQueryStarted: ({ ...patch }, { dispatch, queryFulfilled }) => {
        dispatch(
          usersApi.util.updateQueryData("profile", undefined, (draft) => {
            draft.profile.bananas += patch.bananas;
            return draft;
          })
        );

        queryFulfilled.catch(() => {
          dispatch(usersApi.util.invalidateTags(["profile"]));
        });
      },

      invalidatesTags: ["profile"],
    }),
    logActivity: build.mutation<void, BeginActivityParams>({
      query: (body) => ({
        url: `/activity/log`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["profile"],
    }),
  }),
});

export const {
  useBeginActivityMutation,
  useEndActivityMutation,
  useAbortActivityMutation,
  useLogActivityMutation,
} = activityApi;
