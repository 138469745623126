import { configureStore } from "@reduxjs/toolkit";
import { uiReducer } from "./topLevelReducers";

import { usersApi } from "Store/Services/users";
// import { setupListeners } from "@rtk-incubator/rtk-query";

const store = configureStore({
  reducer: {
    [usersApi.reducerPath]: usersApi.reducer,
    ui: uiReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(usersApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch);

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
