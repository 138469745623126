import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "Store/store";

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).ui.user?.user?.accessToken;

      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["profile"],
  endpoints: () => ({}),
});
