import React, { FunctionComponent } from "react";
import { IPointData } from "pixi.js";

import HTMLContext from "Main/Components/PixiComponents/HTML/HTMLContext";
import ElementWrapper from "Main/Components/PixiComponents/HTML/ElementWrapper";

import controller_icon from "./Media/gamepad.png";
import learning_icon from "./Media/pencil.png";
import song_icon from "./Media/song.png";
import ballet_icon from "./Media/ballet.png";
import reading_icon from "./Media/reading.png";
import "./ButtonImage.css";

type TooltipProps = {
  pos: IPointData;
  title: string;
  description?: string;
  positioning?: "up" | "down";
};

const MapItemTooltip: FunctionComponent<TooltipProps> = (props) => {
  const {
    pos,
    title,
    description = "This is a description",
    positioning = "down",
  } = props;

  const divStyle =
    positioning === "up" ? { transform: "translateY(-200%)" } : {};

  return (
    <HTMLContext showOnMap>
      <ElementWrapper position={pos}>
        <div className="tooltip-wrapper" style={divStyle}>
          <div>{title}</div>
          <div className="tooltip-summary">{description}</div>
          <div className="tooltip-icons-wrap">
            <img
              alt="controller icon"
              className="tooltip-icon"
              src={controller_icon}
              // onClick=
            />
            <img
              alt="learning icon"
              className="tooltip-icon"
              src={learning_icon}
              // onClick=
            />
            <img
              alt="song icon"
              className="tooltip-icon"
              src={song_icon}
              // onClick=
            />
            <img
              alt="ballet icon"
              className="tooltip-icon"
              src={ballet_icon}
              // onClick=
            />
            <img
              alt="reading icon"
              className="tooltip-icon"
              src={reading_icon}
              // onClick=
            />
          </div>
        </div>
      </ElementWrapper>
    </HTMLContext>
  );
};

export default MapItemTooltip;
