// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app-container-padding {
  position: absolute;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
}

.app-container {
  width: 100%;
  height: 100%;
}

.fullscreen {
  /* background: black; */
}

.not-fullscreen {
  padding: 20px 20px 50px;
}
`, "",{"version":3,"sources":["webpack://./src/Main/AppResizer.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".app-container-padding {\n  position: absolute;\n  box-sizing: border-box;\n  width: 100vw;\n  height: 100vh;\n}\n\n.app-container {\n  width: 100%;\n  height: 100%;\n}\n\n.fullscreen {\n  /* background: black; */\n}\n\n.not-fullscreen {\n  padding: 20px 20px 50px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
