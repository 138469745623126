import React, { useState, useEffect, useCallback } from "react";

import { Texture } from "pixi.js";
import { Sprite, Container } from "@inlet/react-pixi";
import ButtonImage from "Main/Components/PixiComponents/ButtonImage";

import useWindowFocus from "Main/Hooks/useWindowFocus";
import { useFirstLoad } from "Main/Hooks/activityData";
import gsap from "gsap";
import fastForwardPNG from "Main/Media/buttons/fastforward.png";

/**
 * @param props.video Pass a video url (will play directly through browser <video> tag)
 * @param props.texture Optionally, pass the VideoTexture directly. Supports StreamingVideoTexture
 * @see StreamingVideoTexture.ts
 */
const OneTimeVideo = (props) => {
  const {
    texture = undefined,
    video,
    image,
    play = false,
    onVideoStatusChanged,
    allowSkip = "never",
    skipToNegativeDuration = undefined,
    ...spriteProps
  } = props;

  const spriteRef = React.useRef(null);

  const [videoTexture] = useState(
    () =>
      texture ||
      Texture.from(video, {
        resourceOptions: {
          autoPlay: false,
        },
      })
  );
  const videoResource = videoTexture.baseTexture?.resource?.source;

  const [videoEnded, setVideoEnded] = useState(false);

  // Callback for when video is ended (or when we prematurely want to end)
  const onEnded = useCallback(() => {
    setVideoEnded(true);
    onVideoStatusChanged(false);
  }, [onVideoStatusChanged]);

  useEffect(() => {
    if (!videoResource) return;
    if (videoEnded) {
      console.log("Got to play");
      videoResource.currentTime = 0;
      videoResource.play().catch((e) => setVideoEnded(true));
      setVideoEnded(false);
    } else if (!videoEnded && !play) {
      console.log("pausing");
      videoResource.pause();
      setVideoEnded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [play, videoResource]);

  useEffect(() => {
    //onVideoStatusChanged(!videoEnded);
  }, [videoEnded, onVideoStatusChanged]);

  // Set up event listeners to the videoResource to listen for 'ended' event
  useEffect(() => {
    if (videoResource) {
      videoResource.addEventListener("ended", onEnded);
      document.VIDEO = videoResource;
    }

    return () => {
      if (videoResource) videoResource.removeEventListener("ended", onEnded);
    };
  }, [videoResource, onEnded]);

  // On unmount of the component, let's make sure to destroy the PIXI.texture
  useEffect(() => {
    return () => {
      if (videoTexture) videoTexture.baseTexture.destroy();
    };
  }, [videoTexture]);

  // When the window focus changes, lets play/pause the video
  const focused = useWindowFocus();
  if (videoResource && !videoEnded) {
    if (focused) {
      videoResource.play().catch((e) => {
        // Autoplay blocked!
        // For now, let's just end the video and show the ending picture
        console.log("focused!" + e);
        setVideoEnded(true);
      });
    }
    // else videoResource.pause();
  }

  const options = { ...spriteProps };

  // Display the video if it's not done. Otherwise, display the image
  if (!videoEnded && videoTexture) {
    options.texture = videoTexture;
  } else {
    options.image = image;
  }

  const firstLoad = useFirstLoad();

  const ffButtonRef = React.useRef(null);

  React.useEffect(() => {
    gsap.to(ffButtonRef.current, {
      keyframes: [
        { pixi: { y: 0 }, delay: 2, duration: 0.5, ease: "back.out(1.6)" },
        {
          pixi: { alpha: 0.2 },
          delay: 5,
          duration: 0.5,
        },
      ],
    });
  }, [firstLoad]);

  const ffPointerOver = React.useCallback(() => {
    gsap.to(ffButtonRef.current, {
      pixi: { alpha: 1 },
      duration: 0.1,
    });
  }, []);

  const ffPointerOut = React.useCallback(() => {
    gsap.to(ffButtonRef.current, {
      pixi: { alpha: 0.2 },
      duration: 0.1,
    });
  }, []);

  const ffPointerTap = React.useCallback(() => {
    const skipToTime = videoResource.duration - (skipToNegativeDuration || 0.1);
    if (videoResource.currentTime < skipToTime) {
      videoResource.currentTime = skipToTime;
    } else {
      videoResource.currentTime = videoResource.duration;
    }
  }, [videoResource, skipToNegativeDuration]);

  return (
    <>
      <Sprite ref={spriteRef} {...options}></Sprite>

      <Container ref={ffButtonRef} y={50}>
        {allowSkip !== "never" && (allowSkip === "always" || !firstLoad) && (
          <Container position={[650, 720]} scale={0.6}>
            <ButtonImage
              pos={[0, 0]}
              img={fastForwardPNG}
              scaleOnHover={1.1}
              pointerover={ffPointerOver}
              pointerout={ffPointerOut}
              pointertap={ffPointerTap}
            />
          </Container>
        )}
      </Container>
    </>
  );
};

export default OneTimeVideo;
