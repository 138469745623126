import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import "./Ararat.css";

import HTMLContext from "Main/Components/PixiComponents/HTML/HTMLContext";

import playButton from "./Media/play.png";
import stopButton from "./Media/stop.png";

import LocationTemplate from "Main/Components/PixiComponents/LocationTemplate";
import OneTimeVideo from "Main/Components/PixiComponents/OneTimeVideo";

import araratAnimImg from "./Media/updated_ABC_Gabig_Image.png?as=webp";
// import araratAnimation from "./Media/Updated_ABC_Gabig_Animation.mp4";

export const resources = {
  // araratAnimation,
  araratAnimImg,
};

const araratAnimUrl =
  "https://abc-gabig-media-store-test.s3.us-west-2.amazonaws.com/ararat-intro-1080-web.m4v";

const Ararat = (props) => {
  const [videoPlaying, setVideoPlaying] = useState(false);
  const [videoPlay, setVideoPlay] = useState(true);

  useEffect(() => {
    if (props.mapOpen && videoPlaying) {
      setVideoPlay(!videoPlay);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mapOpen]);

  const onVideoPlay = () => {
    setVideoPlay(!videoPlay);
  };

  const onVideoStatusChanged = (playing) => {
    console.log("onvideostatuschagned" + playing);
    setVideoPlaying(playing);
  };

  return (
    <LocationTemplate logLocationAsActivity="ararat-home">
      <OneTimeVideo
        play={videoPlay}
        onVideoStatusChanged={onVideoStatusChanged}
        video={araratAnimUrl}
        image={araratAnimImg}
        position={[650, 0]}
        anchor={[0.5, 0]}
        width={1300}
        height={750}
      />

      <HTMLContext receivePointerEvents>
        {props.mapOpen || (
          <div className="video-controls-wrapper">
            <div className="video-controls-button" onClick={onVideoPlay}>
              <img
                className="video-controls-image"
                src={videoPlaying ? stopButton : playButton}
                alt="Play Video"
              />
            </div>
          </div>
        )}
      </HTMLContext>
    </LocationTemplate>
  );
};

const mapStateToProps = (state) => ({
  mapOpen: state.ui.app.mapOpen,
});

export default connect(mapStateToProps)(Ararat);
