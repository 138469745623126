import { _ReactPixi } from "@inlet/react-pixi";

type Point = _ReactPixi.PointLike;

export const pointAdd = (a: Point, b: Point) => {
  return [getX(a) + getX(b), getY(a) + getY(b)] as _ReactPixi.PointLike;
};

export const pointSubtract = (a: Point, b: Point) => {
  return [getX(a) - getX(b), getY(a) - getY(b)] as _ReactPixi.PointLike;
};

export const pointScalarMult = (a: Point, b: number) => {
  return [getX(a) * b, getY(a) * b] as _ReactPixi.PointLike;
};

export const pointDotProd = (a: Point, b: Point) => {
  return [getX(a) * getX(b), getY(a) * getY(b)] as Point;
};

export const pointInverse = (a: Point) => {
  return [1 / getX(a), 1 / getY(a)] as Point;
};

export const getX = (x: Point) => {
  if (typeof x === "object" && x && "x" in (x as any)) {
    return (x as { x: number }).x;
  }

  if (Array.isArray(x)) {
    return (x as number[])[0];
  }

  return x as number;
};

export const getY = (x: Point) => {
  if (typeof x === "object" && x && "y" in (x as any)) {
    return (x as { y: number }).y;
  }

  if (Array.isArray(x)) {
    let arr = x as number[];
    if (arr.length === 2) return arr[1];
    if (arr.length === 1) return arr[0];
  }

  return x as number;
};

export const pointDistance = (a: Point, b: Point) => {
  return Math.sqrt(
    Math.pow(getX(b) - getX(a), 2) + Math.pow(getY(b) - getY(a), 2)
  );
};
