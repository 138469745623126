// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-background {
  width: 100%;
  height: 100%;
  background: #0000003a;
  transition: opacity 0.2s ease;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
}

.modal-box {
  margin: 0px auto;
  background-color: ivory;
  color: black;
  border-radius: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-close-button {
  width: 45px;
  height: 45px;
  position: absolute;
  top: 45px;
  right: 45px;
  cursor: pointer;
  z-index: 5;
}
`, "",{"version":3,"sources":["webpack://./src/Main/Components/Modals/Modal.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,qBAAqB;EACrB,6BAA6B;EAC7B,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;AAClC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,eAAe;EACf,UAAU;AACZ","sourcesContent":[".modal-background {\n  width: 100%;\n  height: 100%;\n  background: #0000003a;\n  transition: opacity 0.2s ease;\n  position: absolute;\n  top: 0;\n  left: 0;\n  z-index: 999;\n}\n\n.modal-box {\n  margin: 0px auto;\n  background-color: ivory;\n  color: black;\n  border-radius: 2px;\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n}\n\n.modal-close-button {\n  width: 45px;\n  height: 45px;\n  position: absolute;\n  top: 45px;\n  right: 45px;\n  cursor: pointer;\n  z-index: 5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
