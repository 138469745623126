import { combineReducers } from "redux";

import appReducer from "./Slices/app";
import userReducer from "./Slices/user";
import gameReducer from "./Slices/game";

export const uiReducer = combineReducers({
  app: appReducer,
  user: userReducer,
  game: gameReducer,
});
