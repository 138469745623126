import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapToggled } from "Store/Slices/app";
import ButtonImage from "Main/Components/PixiComponents/ButtonImage";

// import map_opened from "./Media/MapButton/opened-noface-armenia-border.png";
// import map_closed from "./Media/MapButton/closed-noface-border.png";

import map_opened from "./Media/MapButton/Map-Open.png?as=webp&h=130";
// import map_closed from "./Media/MapButton/Map-Closed.png"
import map_closed from "./Media/MapButton/Map-Closed-Straight.png?as=webp&h=130";

export const resources = {
  map_opened,
  map_closed,
};

const buttonHeight = 125;
const buttonWidth = 225;
const buttonMargin = 25;

const MapButtonImageOptions = {
  pos: [
    1300 - buttonWidth / 2 - buttonMargin,
    750 - buttonHeight / 2 - buttonMargin,
  ],
  // width: buttonSize,
  // height: buttonSize,
  scaleOnHover: 1.1,
};

const MapButton = (props) => {
  const [mapButtonImages, setMapButtonImages] = useState({
    img: map_closed,
    imgHover: map_opened,
  });

  const [isHovering, setIsHovering] = useState(false);

  const onPointerOut = (e) => {
    setIsHovering(false);
  };

  const onPointerOver = (e) => {
    setIsHovering(true);
  };

  useEffect(() => {
    if (!isHovering) {
      if (!props.mapOpen) {
        setMapButtonImages({
          img: map_closed,
          imgHover: map_opened,
        });
      } else {
        setMapButtonImages({
          img: map_opened,
          imgHover: map_closed,
        });
      }
    }
  }, [props.mapOpen, isHovering]);

  return (
    <ButtonImage
      {...MapButtonImageOptions}
      {...mapButtonImages}
      pointerout={onPointerOut}
      pointerover={onPointerOver}
      pointertap={() => props.mapToggled()}
      pixelPerfectInteraction
    />
  );
};

const mapStateToProps = (state) => ({
  mapOpen: state.ui.app.mapOpen,
});

const mapDispatchToProps = (dispatch) => ({
  mapToggled: (payload) => dispatch(mapToggled(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MapButton);
