import React, { Suspense, useEffect } from "react";
import { connect } from "react-redux";
import * as PIXI from "pixi.js";

import { Switch, Route } from "react-router-dom";

import { Container, useApp } from "@inlet/react-pixi";

import ResourceLoader from "Main/Components/PixiComponents/ResourceLoader";
import LoadingScreen from "Main/Components/LoadingScreen/LoadingScreen";
import HTMLContext from "Main/Components/PixiComponents/HTML/HTMLContext";

import TransformTool from "Main/Components/TransformTool/TransformTool";

import Map, { resources as MapResources } from "Main/Apps/Map/Map";
import MapButton, {
  resources as MapButtonResources,
} from "Main/Apps/Map/MapButton";

import Ararat, { resources as AraratResources } from "./Apps/Locations/Ararat";

import { useAppSelector } from "./Hooks/redux";
import { useTranslation } from "react-i18next";
import UIModal from "./Components/UIModal/UIModal";
import { useProfileQuery } from "Store/Services/users";

// Lazy Load all location routers
/* prettier-ignore */ const MotherArmeniaRouter = React.lazy(() => import("./Apps/Locations/MotherArmenia"));
/* prettier-ignore */ const NewspaperRouter = React.lazy(() => import("./Apps/Newspaper/NewspaperRouter"));
/* prettier-ignore */ const LakeSevanRouter = React.lazy(() => import("./Apps/Locations/LakeSevan"));
/* prettier-ignore */ const MamikBabikRouter = React.lazy(() => import("./Apps/Locations/MamikBabik"));
/* prettier-ignore */ const ArdashavanRouter = React.lazy(() => import("./Apps/Locations/Ardashavan").then((module) => ({ default: module.ArdashavanRouter })));
/* prettier-ignore */ const YerevanRouter = React.lazy(() => import("./Apps/Locations/Yerevan").then((module) => ({ default: module.YerevanRouter })));
/* prettier-ignore */ const DilijanRouter = React.lazy(() => import("./Apps/Locations/Dilijan").then((module) => ({ default: module.DilijanRouter })));

const HomePageResources = {
  ...MapButtonResources,
  ...AraratResources,
  ...MapResources,
};

const PixiApp = (props) => {
  const UIModalOpen = useAppSelector((state) => state.ui.app.UIModalOpen);
  const [, i18n] = useTranslation();
  const pixiApp = useApp();

  const { data: userProfile } = useProfileQuery();

  useEffect(() => {
    if (!userProfile) return;

    if (userProfile.preferences.preferredLanguage === "en") {
      i18n.changeLanguage("en");
    } else if (userProfile.preferences.preferredLanguage === "hy") {
      i18n.changeLanguage("hy");
    }
  }, [userProfile, i18n]); // Runs when userResult changes

  // Support Pixi Inspector in Chrome
  useEffect(() => {
    window.__PIXI_INSPECTOR_GLOBAL_HOOK__ &&
      window.__PIXI_INSPECTOR_GLOBAL_HOOK__.register({ PIXI: PIXI });

    window.__PIXI_APP__ = pixiApp;
  }, [pixiApp]);

  return (
    <Suspense
      fallback={
        <HTMLContext>
          <LoadingScreen progress={0} />
        </HTMLContext>
      }
    >
      <ResourceLoader
        resources={HomePageResources}
        onProgress={(progress) => (
          <HTMLContext>
            <LoadingScreen progress={progress} />
          </HTMLContext>
        )}
      >
        {UIModalOpen && <UIModal />}

        <Container>
          <Switch>
            <Route path="/wiki">
              <NewspaperRouter />
            </Route>
            <Route path="/MotherArmenia">
              <MotherArmeniaRouter />
            </Route>
            <Route path="/LakeSevan">
              <LakeSevanRouter />
            </Route>
            <Route path="/MamikBabik">
              <MamikBabikRouter />
            </Route>
            <Route path="/Ardashavan">
              <ArdashavanRouter />
            </Route>
            <Route path="/Hrabarag">
              <YerevanRouter />
            </Route>
            <Route path="/Dilijan">
              <DilijanRouter />
            </Route>
            <Route path="/">
              <Ararat />
            </Route>
          </Switch>

          {props.mapOpen && <Map />}
          <MapButton mapOpen={props.mapOpen} />
        </Container>
        <TransformTool />
      </ResourceLoader>
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  currentLocation: state.ui.app.currentLocation,
  mapOpen: state.ui.app.mapOpen,
  modalOpen: state.ui.app.modalOpen,
});

export default connect(mapStateToProps)(PixiApp);
