import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type Language = "am" | "en";

interface AppState {
  mapOpen: boolean;
  fullscreen: boolean;
  verticalMenu: boolean;
  UIModalOpen: boolean;
  language: Language;
  stageHTMLOverlay: string | null;
}

const initialState: AppState = {
  mapOpen: false,
  fullscreen: false,
  verticalMenu: false,
  UIModalOpen: false,
  language: "am",
  stageHTMLOverlay: null,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    mapToggled: (state) => {
      state.mapOpen = !state.mapOpen;
    },
    closeMap: (state) => {
      state.mapOpen = false;
    },
    setUIModalOpen: (state, action: PayloadAction<boolean>) => {
      state.UIModalOpen = action.payload;
    },
    setFullscreen: (state, action: PayloadAction<boolean>) => {
      state.fullscreen = action.payload;
    },
    setVerticalMenu: (state, action: PayloadAction<boolean>) => {
      state.verticalMenu = action.payload;
    },
    languageChanged: (state, action: PayloadAction<Language>) => {
      state.language = action.payload;
    },
    setStageHTMLOverlay: (stage, action: PayloadAction<string>) => {
      stage.stageHTMLOverlay = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  mapToggled,
  closeMap,
  setUIModalOpen,
  setFullscreen,
  setVerticalMenu,
  languageChanged,
  setStageHTMLOverlay,
} = slice.actions;
