import React, { FunctionComponent, ReactElement, useState } from "react";
import useLoader from "Main/Hooks/useLoader";

import HTMLContext from "Main/Components/PixiComponents/HTML/HTMLContext";
import LoadingScreen from "Main/Components/LoadingScreen/LoadingScreen";
import { useApp } from "@inlet/react-pixi";
import { Loader } from "pixi.js";

const defaultLoadingScreen: LoadingScreenFunction = (progress) => (
  <HTMLContext>
    <LoadingScreen progress={progress} />
  </HTMLContext>
);

type LoadingScreenFunction = (progress: number) => ReactElement;

type WithLoader = (loader: Loader) => React.ReactNode;

type ResourceLoaderProps = {
  resources: { [key: string]: string };
  onProgress?: LoadingScreenFunction;

  children: React.ReactNode | WithLoader;

  preUploadTexturesToGPU?: boolean;
};

const ResourceLoader: FunctionComponent<ResourceLoaderProps> = (props) => {
  const { resources, onProgress = defaultLoadingScreen } = props;

  const app = useApp();
  const [isLoaded, progress, loader] = useLoader(resources);
  const [uploaded, setUploaded] = useState(false);

  if (!isLoaded) {
    return onProgress(progress);
  }

  if (props.preUploadTexturesToGPU) {
    const textures = [];
    for (let resource of Object.values(loader.resources))
      if (resource.texture) textures.push(resource.texture);

    for (let texture of textures) app.renderer.plugins.prepare.add(texture);
    app.renderer.plugins.prepare.completes = [
      () => {
        setUploaded(true);
      },
    ];
    app.renderer.plugins.prepare.upload();
  }

  if (props.preUploadTexturesToGPU && !uploaded) return onProgress(100);

  if (isFunction(props.children))
    return <>{(props.children as WithLoader)(loader)}</>;

  return <>{props.children}</>;
};

export default ResourceLoader;

const isFunction = (obj: any) =>
  !!(obj && obj.constructor && obj.call && obj.apply);
