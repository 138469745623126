import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";
// import { abcGabigApi } from "Store/Services/abcGabigApi";
// import { usersApi } from "Store/Services/users";
import { IUserState } from "./user.interface";

const initialState: IUserState = {
  loggedIn: false,
  loginError: null,
  user: null,
  language: "en",
};

export const getUserInfo = createAsyncThunk("users/getUserInfo", async () => {
  return await Auth.currentAuthenticatedUser();
});

export const loginWithCredentials = createAsyncThunk<
  any,
  { username: string; password: string }
>("users/loginWithCredentials", async (credentials) => {
  return await Auth.signIn(credentials);
});

export const logout = createAsyncThunk("users/logout", async () => {
  await Auth.signOut();
});

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.user = {
          username: action.payload.username,
          accessToken: action.payload.signInUserSession.accessToken.jwtToken,
          attributes: action.payload.attributes,
          language: action.payload.language,
        };
      })
      .addCase(getUserInfo.rejected, (state) => {
        state.loggedIn = false;
        state.user = null;
      })
      .addCase(loginWithCredentials.fulfilled, (state, action) => {
        state.loggedIn = true;
        state.loginError = null;
        state.user = {
          username: action.payload.username,
          accessToken: action.payload.signInUserSession.accessToken.jwtToken,
          attributes: action.payload.attributes,
          language: action.payload.language,
        };
      })
      .addCase(loginWithCredentials.rejected, (state) => {
        state.loggedIn = false;
        state.loginError = "LOGIN_FAILED";
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loggedIn = false;
        state.loginError = null;
        state.user = null;
      });
  },
});

export default slice.reducer;
