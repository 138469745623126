import React from "react";
import { Provider } from "react-redux";
import store from "Store/store";
import { I18nextProvider } from "react-i18next";
import i18n from "../i18n.ts";
import CustomRouter from "Main/Components/CustomRouter";

export const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <CustomRouter>{children}</CustomRouter>
      </I18nextProvider>
    </Provider>
  );
};
